import { Injectable } from '@angular/core';
/* Import firebase v9 */
import { app as firebase } from '../../../firebase-config';
import { getFirestore, collection, getDocs, DocumentData, QuerySnapshot, DocumentSnapshot, doc, getDoc, updateDoc, arrayUnion, addDoc, where, query, deleteDoc, orderBy } from 'firebase/firestore';
import { BehaviorSubject } from 'rxjs';
import { Transfer } from 'src/app/models/transfer';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})

export class TransfersService {

  private eventAuthError = new BehaviorSubject<string>("");
  eventAuthError$ = this.eventAuthError.asObservable();
  private db = getFirestore(firebase);

  constructor(private datePipe: DatePipe) { }

  public async addTransfer(currentUserId: string, transfer: Transfer): Promise<Transfer> {
    try {
      const docRef = await addDoc(collection(this.db, 'users', currentUserId, "transfers"), transfer);
      if (docRef) {
        await updateDoc(docRef, {
          transferId: docRef.id,
          transferShortId: docRef.id.substring(0, 5)
        })
      }
      return transfer;
    } catch (error) {
      this.eventAuthError.next(error);
      throw new Error(error);
    }
  }

  async getAllTransfersByRepId(userId: string): Promise<Transfer[]> {
    let transfers: Transfer[] = [];
    try {
      const q =  query(collection(this.db, "users", userId, "transfers"), orderBy("transferDate"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(doc => {
        // doc.data() is never undefined for query doc snapshots
        const data = doc.data() as Transfer;
        transfers.push(data);
      });
      return transfers;
    } catch (error) {
      this.eventAuthError.next(error);
    }
  }

  async getAllApprovedTransfersByRepId(userId: string, projectId: string): Promise<Transfer[]> {
    let transfers: Transfer[] = [];
    try {
      const q =  query(collection(this.db, "users", userId, "transfers"), where('projectId', '==', projectId), where('transferStatus', '==', "Approved"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(doc => {
        // doc.data() is never undefined for query doc snapshots
        const data = doc.data() as Transfer;
        transfers.push(data);
      });
      return transfers;
    } catch (error) {
      this.eventAuthError.next(error);
    }
  }

  public async getTransferByRepId(repId: string, transferId: string): Promise<Transfer> {
    try {
      const docRef = doc(this.db, "users", repId, "transfers", transferId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const transfer = docSnap.data() as Transfer;
        return transfer;
      } else {
        return null;
      }  
    } catch (error) {
      this.eventAuthError.next(error);
    }
  }

  public async deleteTransferByUserId(userId: string, transferId: string): Promise<void> {
    try {
      return await deleteDoc(doc(this.db, "users", userId, "transfers", transferId));
    } catch (error) {
      this.eventAuthError.next(error);
    }
  }

  public async updateTransfer(currentUserId: string, repId: string,  transferId: string, transfer: Transfer): Promise<Transfer> {
    try {
      const docRef = doc(this.db, 'users', repId, "transfers", transferId);
      if (docRef) {
        await updateDoc(docRef, {
          transferStatus: transfer.transferStatus,
          transferNote: transfer.transferNote,
          listOfDonations: transfer.listOfDonations,
          transferUpdatedUserId: currentUserId, 
          transferUpdatedDate: this.datePipe.transform(new Date(), 'MM/dd/yyyy HH:mm:ss'),
        })
      };
      return transfer;
    } catch (error) {
      this.eventAuthError.next(error);
      throw new Error(error);
    }
  }


}
