import { Component, OnInit } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AppService } from '../../../app.service';
import { Settings, AppSettings } from '../../../app.settings';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html'
})
export class TopMenuComponent implements OnInit {

  isUserVerified$: Observable<boolean>;
  
  public settings: Settings;
  constructor(
    public appSettings:AppSettings, 
    public appService:AppService,
    public auth: AuthService) { 
    this.settings = this.appSettings.settings; 
  } 

  ngOnInit() {
    this.isUserVerified$ = this.auth.user$
              .pipe(
                map(user => {
                 return user !== null ? true : false;
                })
              );
  }

}
