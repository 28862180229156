<mat-sidenav-container> 

    <mat-sidenav [opened]="false" mode="over" #sidenav class="sidenav mat-elevation-z6">
        <button mat-icon-button color="warn" class="close" (click)="sidenav.close()">
           <mat-icon color="warn">close</mat-icon>
        </button>
        <div class="divider"></div>
    </mat-sidenav>


    <mat-toolbar id="header-toolbar">
        <mat-toolbar-row fxLayoutAlign="space-between center" class="top-toolbar theme-container">
            <span fxHide="false" fxHide.gt-sm>
                <button mat-button (click)="sidenav.toggle()" class="sidenav-toggle">
                    <mat-icon>menu</mat-icon>
                </button> 
            </span>
            <app-top-menu></app-top-menu>       
        </mat-toolbar-row>
        
    </mat-toolbar> 

    <div class="theme-container main"> 
        <app-breadcrumb></app-breadcrumb>
        <router-outlet></router-outlet>
    </div>

    <div *ngIf="showBackToTop" fxLayout="row" fxLayoutAlign="center center" class="back-to-top transition" (click)="scrollToTop()">
        <mat-icon>arrow_upward</mat-icon>
    </div>

    <app-footer></app-footer>

 </mat-sidenav-container>