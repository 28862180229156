import { SidenavMenu } from './sidenav-menu.model';

export const sidenavMenuItems = [ 
    
    new SidenavMenu (1, 'All Categories', '/', null, null, false, 0),
    new SidenavMenu (20, 'Fashion', null, null, null, true, 0), 
    new SidenavMenu (21, "Women's clothing", null, null, null, true, 20),   
    new SidenavMenu (22, 'Dresses', '/srchByCategory/Dresses', null, null, false, 21),
    new SidenavMenu (23, 'Tops & Blouses', '/srchByCategory/Tops & Blouses', null, null, false, 21),
    new SidenavMenu (24, 'Women Jeans', '/srchByCategory/Women Jeans', null, null, false, 21),
    new SidenavMenu (25, 'Women Pants', '/srchByCategory/Women Pants', null, null, false, 21),
    new SidenavMenu (26, 'Women Sweaters', '/srchByCategory/Women Sweaters', null, null, false, 21),
    new SidenavMenu (27, 'Women Coats & Jackets', '/srchByCategory/Women Coats & Jackets', null, null, false, 21),
    new SidenavMenu (28, 'Leggings', '/srchByCategory/Leggings', null, null, false, 21),
    new SidenavMenu (29, 'Women Activewear', '/srchByCategory/Women Activewear', null, null, false, 21),
    new SidenavMenu (30, 'Maternity Clothing', '/srchByCategory/Maternity Clothing', null, null, false, 21),
    new SidenavMenu (31, 'Others Women Clothing', '/srchByCategory/Others Women Clothing', null, null, false, 21),
    
    new SidenavMenu (41, "Men's clothing", null, null, null, true, 20), 
    new SidenavMenu (42, 'T-Shirts', '/srchByCategory/T-Shirts', null, null, false, 41),
    new SidenavMenu (43, 'Men Jeans', '/srchByCategory/Men Jeans', null, null, false, 41),
    new SidenavMenu (44, 'Men Pants', '/srchByCategory/Men Pants', null, null, false, 41),
    new SidenavMenu (45, 'Dress Shirts', '/srchByCategory/Dress Shirts', null, null, false, 41),
    new SidenavMenu (46, 'Men Activewear', '/srchByCategory/Men Activewear', null, null, false, 41),
    new SidenavMenu (47, 'Sweats & Hoodies', '/srchByCategory/Sweats & Hoodies', null, null, false, 41),
    new SidenavMenu (48, 'Men Sweaters', '/srchByCategory/Men Sweaters', null, null, false, 41),
    new SidenavMenu (49, 'Men Coats & Jackets', '/srchByCategory/Men Coats & Jackets', null, null, false, 41),
    new SidenavMenu (50, 'Men Suits', '/srchByCategory/Men Suits', null, null, false, 41),
    new SidenavMenu (51, 'Others Men Clothing', '/srchByCategory/Others Men Clothing', null, null, false, 41),
    
    new SidenavMenu (61, "Kids & Baby Clothing", null, null, null, true, 20),
    new SidenavMenu (62, 'Baby Clothing (Newborn - 5T)', '/srchByCategory/Baby Clothing (Newborn - 5T)', null, null, false, 61),
    new SidenavMenu (62, 'Girls Clothing (Sizes 5 & Up)', '/srchByCategory/Girls Clothing (Sizes 5 & Up)', null, null, false, 61),
    new SidenavMenu (62, 'Boys Clothing (Sizes 5 & Up)', '/srchByCategory/Boys Clothing (Sizes 5 & Up)', null, null, false, 61),
    
    new SidenavMenu (71, "Jewellery", null, null, null, true, 20),
    new SidenavMenu (72, 'Fashion Necklaces & Pendants', '/srchByCategory/Fashion Necklaces & Pendants', null, null, false, 71),
    new SidenavMenu (73, 'Fine Necklaces & Pendants', '/srchByCategory/Fine Necklaces & Pendants', null, null, false, 71),
    new SidenavMenu (74, 'Fashion Earrings', '/srchByCategory/Fashion Earrings', null, null, false, 71),
    new SidenavMenu (75, 'Fine Earrings', '/srchByCategory/Fine Earrings', null, null, false, 71),
    new SidenavMenu (76, 'Fashion Bracelets', '/srchByCategory/Fashion Bracelets', null, null, false, 71),
    new SidenavMenu (77, 'Fine Bracelets', '/srchByCategory/Fine Bracelets', null, null, false, 71),
    new SidenavMenu (78, 'Fashion Rings', '/srchByCategory/Fashion Rings', null, null, false, 71),
    new SidenavMenu (79, 'Fine Rings', '/srchByCategory/Fine Rings', null, null, false, 71),
    new SidenavMenu (80, 'Engagement & Wedding', '/srchByCategory/Engagement & Wedding', null, null, false, 71),
    new SidenavMenu (81, 'Men Jewellery', '/srchByCategory/Men Jewellery', null, null, false, 71),
    new SidenavMenu (82, 'Others Jewellery', '/srchByCategory/Others Jewellery', null, null, false, 71),

    new SidenavMenu (91, "Shoes", null, null, null, true, 20),
    new SidenavMenu (92, 'Women Shoes', '/srchByCategory/Women Shoes', null, null, false, 91),
    new SidenavMenu (93, 'Men Shoes', '/srchByCategory/Men Shoes', null, null, false, 91),
    new SidenavMenu (94, 'Girls Shoes', '/srchByCategory/Girls Shoes', null, null, false, 91),
    new SidenavMenu (95, 'Boys Shoes', '/srchByCategory/Boys Shoes', null, null, false, 91),
    new SidenavMenu (96, 'Baby & Toddler Shoes', '/srchByCategory/Baby & Toddler Shoes', null, null, false, 91),

    new SidenavMenu (101, "Watches", null, null, null, true, 20),
    new SidenavMenu (102, 'Men Watches', '/srchByCategory/Men Watches', null, null, false, 101),
    new SidenavMenu (103, 'Women Watches', '/srchByCategory/Women Watches', null, null, false, 101),
   
    new SidenavMenu (105, "Watches", null, null, null, true, 20),
    new SidenavMenu (106, 'Women Accessories', '/srchByCategory/Women Accessories', null, null, false, 105),
    new SidenavMenu (107, 'Men Accessories', '/srchByCategory/Men Accessories', null, null, false, 105),

    new SidenavMenu (110, 'Electronics', null, null, null, true, 0), 
    new SidenavMenu (111, 'See all in Electronics', '/srchByCategory/See all in Electronics', null, null, false, 110),
    new SidenavMenu (112, "Computers & Tablets", null, null, null, true, 110),
    new SidenavMenu (113, 'Laptops & Netbooks', '/srchByCategory/Laptops & Netbooks', null, null, false, 112),
    new SidenavMenu (114, 'Computer Components & Parts', '/srchByCategory/Computer Components & Parts', null, null, false, 112),
    new SidenavMenu (115, 'Desktops & All-In-Ones', '/srchByCategory/Desktops & All-In-Ones', null, null, false, 112),
    new SidenavMenu (116, 'Computer Cables & Connectors', '/srchByCategory/Computer Cables & Connectors', null, null, false, 112),
    new SidenavMenu (117, 'Drives & Storages', '/srchByCategory/Drives & Storages', null, null, false, 112),
    new SidenavMenu (118, 'Keyboards, Mice & Pointers', '/srchByCategory/Keyboards, Mice & Pointers', null, null, false, 112),
    new SidenavMenu (119, 'Laptop & Desktop Accessories', '/srchByCategory/Laptop & Desktop Accessories', null, null, false, 112),
    new SidenavMenu (120, 'Monitors & Projectors', '/srchByCategory/Monitors & Projectors', null, null, false, 112),
    new SidenavMenu (121, 'Printers, Scanners & Supplies', '/srchByCategory/Printers, Scanners & Supplies', null, null, false, 112),
    new SidenavMenu (122, 'Tablets & eBook Readers', '/srchByCategory/Tablets & eBook Readers', null, null, false, 112),
    new SidenavMenu (123, 'Tablets & eBook Readers Parts', '/srchByCategory/Tablets & eBook Readers Parts', null, null, false, 112),
    new SidenavMenu (124, 'Others Computers & Tablets', '/srchByCategory/Others Computers & Tablets', null, null, false, 112),

    new SidenavMenu (131, "Cell Phones & Smartphones", null, null, null, true, 110),
    new SidenavMenu (142, 'Cell Phones & Smartphones', '/srchByCategory/Cell Phones & Smartphones', null, null, false, 131),
    new SidenavMenu (143, 'Cell Phone & Smartphones Accessories', '/srchByCategory/Cell Phone & Smartphones Accessories', null, null, false, 131),
    new SidenavMenu (144, 'Cell Phones & Smartphones Parts', '/srchByCategory/Cell Phones & Smartphones Parts', null, null, false, 131),
    new SidenavMenu (145, 'Others Cell Phones & Smartphones', '/srchByCategory/Others Cell Phones & Smartphones', null, null, false, 131),

    new SidenavMenu (151, "TV & Home Entertainment", null, null, null, true, 110),
    new SidenavMenu (152, 'TV & Video', '/srchByCategory/TV & Video', null, null, false, 151),
    new SidenavMenu (153, 'Home Audio', '/srchByCategory/Home Audio', null, null, false, 151),
    new SidenavMenu (154, 'Media Streamers', '/srchByCategory/Media Streamers', null, null, false, 151),
    new SidenavMenu (155, 'TV, Video & Home Audio Accessories', '/srchByCategory/TV, Video & Home Audio Accessories', null, null, false, 151),
    new SidenavMenu (156, 'TV, Video & Home Audio Parts', '/srchByCategory/TV, Video & Home Audio Parts', null, null, false, 151),
    new SidenavMenu (157, 'Others TV, Video & Home Audio', '/srchByCategory/Others TV, Video & Home Audio', null, null, false, 151),

    new SidenavMenu (161, "Refurbished", null, null, null, true, 110),
    new SidenavMenu (162, 'Refurbished Laptops', '/srchByCategory/Refurbished Laptops', null, null, false, 161),
    new SidenavMenu (163, 'Refurbished Portable Audio & Headphones', '/srchByCategory/Refurbished Portable Audio & Headphones', null, null, false, 161),
    new SidenavMenu (164, 'Refurbished Desktop Computers', '/srchByCategory/Refurbished Desktop Computers', null, null, false, 161),
    new SidenavMenu (165, 'Refurbished Monitors', '/srchByCategory/Refurbished Monitors', null, null, false, 161),
    new SidenavMenu (166, 'Others Refurbished', '/srchByCategory/Others Refurbished', null, null, false, 161),

    new SidenavMenu (170, 'Furnitures', null, null, null, true, 0), 
    new SidenavMenu (171, "Living Room", null, null, null, true, 170),
    new SidenavMenu (172, 'Middle East Furniture', '/srchByCategory/Middle East Furniture', null, null, false, 171),
    new SidenavMenu (173, 'Sofas', '/srchByCategory/Sofas', null, null, false, 171),
    new SidenavMenu (174, 'Chairs', '/srchByCategory/Chairs', null, null, false, 171),
    new SidenavMenu (175, 'Loveseats', '/srchByCategory/Loveseats', null, null, false, 171),
    new SidenavMenu (176, 'Sectionals', '/srchByCategory/Sectionals', null, null, false, 171),
    new SidenavMenu (177, 'Recliners', '/srchByCategory/Recliners', null, null, false, 171),
    new SidenavMenu (178, 'Others Living Room', '/srchByCategory/Others Living Room', null, null, false, 171),

    new SidenavMenu (181, "Bedroom", null, null, null, true, 170),
    new SidenavMenu (182, 'Queen Beds', '/srchByCategory/Queen Beds', null, null, false, 181),
    new SidenavMenu (183, 'King Beds', '/srchByCategory/King Beds', null, null, false, 181),
    new SidenavMenu (184, 'Full Beds', '/srchByCategory/Full Beds', null, null, false, 181),
    new SidenavMenu (185, 'Dressers & Chests', '/srchByCategory/Dressers & Chests', null, null, false, 181),
    new SidenavMenu (186, 'Night Tables', '/srchByCategory/Night Tables', null, null, false, 181),
    new SidenavMenu (187, 'Mirrors', '/srchByCategory/Mirrors', null, null, false, 181),
    new SidenavMenu (188, 'Others Bedroom', '/srchByCategory/Others Bedroom', null, null, false, 181),

    new SidenavMenu (191, "Dining Room", null, null, null, true, 170),
    new SidenavMenu (192, 'Dining Tables', '/srchByCategory/Dining Tables', null, null, false, 191),
    new SidenavMenu (193, 'Dining Chairs', '/srchByCategory/Dining Chairs', null, null, false, 191),
    new SidenavMenu (194, 'Dining Mirrors', '/srchByCategory/Dining Mirrors', null, null, false, 191),
    new SidenavMenu (195, 'Others Dining Room', '/srchByCategory/Others Dining Room', null, null, false, 191),

    new SidenavMenu (201, "Kids Room", null, null, null, true, 170),
    new SidenavMenu (202, 'Bunk Beds', '/srchByCategory/Bunk Beds', null, null, false, 201),
    new SidenavMenu (203, 'Twin Beds', '/srchByCategory/Twin Beds', null, null, false, 201),
    new SidenavMenu (204, 'Full Beds', '/srchByCategory/Full Beds', null, null, false, 201),
    new SidenavMenu (205, 'Desks & Hutches', '/srchByCategory/Desks & Hutches', null, null, false, 201),
    new SidenavMenu (206, 'Baby Furnitures', '/srchByCategory/Baby Furnitures', null, null, false, 201),
    new SidenavMenu (207, '"Others Kids Room', '/srchByCategory/"Others Kids Room', null, null, false, 201),

    new SidenavMenu (210, 'Appliances', null, null, null, true, 0),
    new SidenavMenu (211, "Kitchen", null, null, null, true, 210),
    new SidenavMenu (212, 'Refrigerators', '/srchByCategory/Refrigerators', null, null, false, 211),
    new SidenavMenu (213, 'Ranges', '/srchByCategory/Ranges', null, null, false, 211),
    new SidenavMenu (214, 'Dishwashers', '/srchByCategory/Dishwashers', null, null, false, 211),
    new SidenavMenu (215, 'Microwaves', '/srchByCategory/Microwaves', null, null, false, 211),
    new SidenavMenu (216, 'Cooktops', '/srchByCategory/Cooktops', null, null, false, 211),
    new SidenavMenu (217, 'Wall ovens', '/srchByCategory/Wall ovens', null, null, false, 211),
    new SidenavMenu (218, 'Range hoods', '/srchByCategory/Range hoods', null, null, false, 211),
    new SidenavMenu (219, 'Freezers', '/srchByCategory/Freezers', null, null, false, 211),
    new SidenavMenu (220, 'Others Kitchen', '/srchByCategory/Others Kitchen', null, null, false, 211),

    new SidenavMenu (231, "Kitchen", null, null, null, true, 210),
    new SidenavMenu (232, 'Washers', '/srchByCategory/Washers', null, null, false, 231),
    new SidenavMenu (233, 'Dryer', '/srchByCategory/Dryer', null, null, false, 231),
    new SidenavMenu (234, 'Washer & Dryer Pairs', '/srchByCategory/Washer & Dryer Pairs', null, null, false, 231),
    new SidenavMenu (235, 'Others Laundry', '/srchByCategory/Others Laundry', null, null, false, 231),

    new SidenavMenu (240, "Cars & Vehicles", null, null, null, true, 0),
    new SidenavMenu (241, 'Cars, Trucks & Buses', null, null, null, true, 240),
    new SidenavMenu (242, "Cars", "/srchByCategory/Cars", null, null, false, 241),
    new SidenavMenu (243, "Trucks", "/srchByCategory/Trucks", null, null, false, 241),
    new SidenavMenu (244, "Buses", "/srchByCategory/Buses", null, null, false, 241),

    new SidenavMenu (250, 'Vehicle Parts, Tires, & Accessories', null, null, null, true, 240),   
    new SidenavMenu (251, "Engine & Engine Parts", "/srchByCategory/Engine & Engine Parts", null, null, false, 250),
    new SidenavMenu (252, "Auto Body Parts", "/srchByCategory/Auto Body Parts", null, null, false, 250),
    new SidenavMenu (253, "Heavy Equipment Parts & Accessories", "/srchByCategory/Heavy Equipment Parts & Accessories", null, null, false, 250),
    new SidenavMenu (254, "Tires & Rims", "/srchByCategory/Tires & Rims", null, null, false, 250),
    new SidenavMenu (255, "Transmission & Drivetrain", "/srchByCategory/Transmission & Drivetrain", null, null, false, 250),
    new SidenavMenu (256, "Other Parts & Accessories", "/srchByCategory/Other Parts & Accessories", null, null, false, 250),

    new SidenavMenu (260, "Real Estate", null, null, null, true, 0),
    
    new SidenavMenu (261, 'For Rent', null, null, null, true, 260),
    new SidenavMenu (262, "Long Term Rentals", "/srchByCategory/Long Term Rentals", null, null, false, 261),
    new SidenavMenu (263, "Short Term Rentals", "/srchByCategory/Short Term Rentals", null, null, false, 261),
    new SidenavMenu (264, "Storage for Rent", "/srchByCategory/Storage for Rent", null, null, false, 261),
    new SidenavMenu (265, "Parking for Rent", "/srchByCategory/Parking for Rent", null, null, false, 261),
    new SidenavMenu (266, "Commercial & Office Space for Rent", "/srchByCategory/Commercial & Office Space for Rent", null, null, false, 261),

    new SidenavMenu (270, 'For Sale', null, null, null, true, 260),
    new SidenavMenu (271, "Houses for Sale", "/srchByCategory/Houses for Sale", null, null, false, 270),
    new SidenavMenu (272, "Condos for Sale", "/srchByCategory/Condos for Sale", null, null, false, 270),
    new SidenavMenu (273, "Land for Sale", "/srchByCategory/Land for Sale", null, null, false, 270),
    new SidenavMenu (274, "Commercial & Office Space for Sale", "/srchByCategory/Commercial & Office Space for Sale", null, null, false, 270),


    new SidenavMenu (280, "Health & Beauty", null, null, null, true, 0),
    new SidenavMenu (281, 'Fragrances', null, null, null, true, 280),
    new SidenavMenu (282, "Women Fragrances", "/srchByCategory/Women Fragrances", null, null, false, 281),
    new SidenavMenu (283, "Men Fragrances", "/srchByCategory/Men Fragrances", null, null, false, 281),
    new SidenavMenu (284, "Children Fragrances", "/srchByCategory/Children Fragrances", null, null, false, 281),

    new SidenavMenu (285, 'Bath & Body', null, null, null, true, 280),
    new SidenavMenu (286, "Body Sprays & Mists", "/srchByCategory/Body Sprays & Mists", null, null, false, 285),
    new SidenavMenu (287, "Body Soaps", "/srchByCategory/Body Soaps", null, null, false, 285),
    new SidenavMenu (288, "Deodourants & Antiperspirants", "/srchByCategory/Deodourants & Antiperspirants", null, null, false, 285),
    new SidenavMenu (289, "Body Washes & Shower Gels", "/srchByCategory/Body Washes & Shower Gels", null, null, false, 285),
    new SidenavMenu (290, "Bath Sets & Kits", "/srchByCategory/Bath Sets & Kits", null, null, false, 285),
    new SidenavMenu (291, "Bath & Body Mixed Items", "/srchByCategory/Bath & Body Mixed Items", null, null, false, 285),
    new SidenavMenu (292, "Body Powders", "/srchByCategory/Body Powders", null, null, false, 285),
    new SidenavMenu (293, "Hand Washes", "/srchByCategory/Hand Washes", null, null, false, 285),
    new SidenavMenu (294, "Travel Bottles & Containers", "/srchByCategory/Travel Bottles & Containers", null, null, false, 285),
    new SidenavMenu (295, "Bath Oils", "/srchByCategory/Bath Oils", null, null, false, 285),
    new SidenavMenu (296, "Others Bath Oils", "/srchByCategory/Others Bath Oils", null, null, false, 285),

    new SidenavMenu (297, 'Skin Care', null, null, null, true, 280),
    new SidenavMenu (298, "Moisturizers", "/srchByCategory/Moisturizers", null, null, false, 297),
    new SidenavMenu (299, "Anti-Aging Products", "/srchByCategory/Anti-Aging Products", null, null, false, 297),
    new SidenavMenu (278, "Others Skin Care", "/srchByCategory/Others Skin Care", null, null, false, 297),

    new SidenavMenu (300, "Services", null, null, null, true, 0),

    new SidenavMenu (301, 'General Services', null, null, null, true, 300),
    new SidenavMenu (302, "Childcare & Nanny", "/srchByCategory/Childcare & Nanny", null, null, false, 301),
    new SidenavMenu (303, "Cleaners & Cleaning", "/srchByCategory/Cleaners & Cleaning", null, null, false, 301),
    new SidenavMenu (304, "Financial & Legal", "/srchByCategory/Financial & Legal", null, null, false, 301),
    new SidenavMenu (305, "Food & Catering", "/srchByCategory/Food & Catering", null, null, false, 301),
    new SidenavMenu (306, "Health & Beauty", "/srchByCategory/Health & Beauty", null, null, false, 301),
    new SidenavMenu (307, "Moving & Storage", "/srchByCategory/Moving & Storage", null, null, false, 301),
    new SidenavMenu (308, "Tutors & Languages", "/srchByCategory/Tutors & Languages", null, null, false, 301),

    new SidenavMenu (320, 'Skilled Trades', null, null, null, true, 300),
    new SidenavMenu (321, "Appliance Repair & Installation", "/srchByCategory/Appliance Repair & Installation", null, null, false, 320),
    new SidenavMenu (322, "Brick, Masonry & Concrete", "/srchByCategory/Brick, Masonry & Concrete", null, null, false, 320),
    new SidenavMenu (323, "Carpentry, Crown Moulding & Trimwork", "/srchByCategory/Carpentry, Crown Moulding & Trimwork", null, null, false, 320),
    new SidenavMenu (324, "Drywall & Stucco Removal", "/srchByCategory/Drywall & Stucco Removal", null, null, false, 320),
    new SidenavMenu (325, "Electrician", "/srchByCategory/Electrician", null, null, false, 320),
    new SidenavMenu (326, "Flooring", "/srchByCategory/Flooring", null, null, false, 320),
    new SidenavMenu (327, "Heating, Ventilation & Air Conditioning", "/srchByCategory/Heating, Ventilation & Air Conditioning", null, null, false, 320),
    new SidenavMenu (328, "Interlock, Paving & Driveways", "/srchByCategoryInterlock, Paving & Driveways", null, null, false, 320),
    new SidenavMenu (329, "Painters & Painting", "/srchByCategory/Painters & Painting", null, null, false, 320),
    new SidenavMenu (330, "Phone, Network, Cable & Home-wiring", "/srchByCategory/Phone, Network, Cable & Home-wiring", null, null, false, 320),
    new SidenavMenu (331, "Plumbing", "/srchByCategory/Plumbing", null, null, false, 320),
    new SidenavMenu (332, "Renovations, General Contracting & Handyman", "/srchByCategory/Renovations, General Contracting & Handyman", null, null, false, 320),
    new SidenavMenu (333, "Roofing", "/srchByCategory/Roofing", null, null, false, 320),
    new SidenavMenu (334, "Welding", "/srchByCategory/Welding", null, null, false, 320),
    new SidenavMenu (335, "Windows & Doors", "/srchByCategory/Windows & Doors", null, null, false, 320)
]