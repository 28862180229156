import { Injectable, NgZone } from '@angular/core';

/* Import firebase v9 */
import { app as firebase } from '../../../firebase-config';
import { 
  getAuth, 
  signInWithEmailAndPassword,
  signOut, 
  onAuthStateChanged, 
  UserCredential,
  updatePassword,
  createUserWithEmailAndPassword} from 'firebase/auth';
import { doc, getFirestore, setDoc } from 'firebase/firestore';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/models/user';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private auth = getAuth(firebase);
  public user$: Observable<User | null>;
  private eventAuthError = new BehaviorSubject<string>("");
  eventAuthError$ = this.eventAuthError.asObservable();
  private db = getFirestore(firebase);
  readonly GENERIC_PASSWORD = "Canada$01";

  constructor(
    public ngZone: NgZone,
    private router: Router,
    private route: ActivatedRoute,
    private datepipe: DatePipe,
    ) { 
    this.user$ = new Observable((observer: any) => onAuthStateChanged(this.auth, observer));
  }

  registerEmailUser(fname: string, mname: string, lname: string,
                      sexe: string, telephone: string, city: string, state: string, country: string, role: string,
                        email: string) {
    return from(createUserWithEmailAndPassword(this.auth, email, this.GENERIC_PASSWORD)
      .then(userCredential => {
        this.registerEmailUserInfo(userCredential, fname, mname, lname, sexe, telephone, city, state, country, role);
      })
      .catch((error) => {
        this.eventAuthError.next(error);
      })
    );
  }


  private async registerEmailUserInfo(credential: UserCredential, 
                                        fname: string, mname: string, lname: string,
                                          sexe: string, telephone: string, city: string, state: string, country: string, role: string): Promise<User>  {
    try {
      const db = getFirestore(firebase);
      const documentRef = doc(db, 'users', credential.user.uid);
      const data: User = {
        uid: credential.user.uid,
        email: credential.user.email,
        fname: fname,
        mname: mname,
        lname: lname,
        sexe:  sexe,
        city:  city,
        state: state,
        country: country,
        role: role,
        createdUserDate: this.datepipe.transform(new Date(), 'yyyy-MM-dd'),
      }
      await setDoc(documentRef, data);
      return data;

    } catch (error) {
      this.eventAuthError.next(error);
    }
  }
  

  async logout() {
    try {
      await signOut(this.auth);
      sessionStorage.removeItem("userId");
      sessionStorage.removeItem("returnUrl");
      sessionStorage.removeItem('lastTime');

      this.router.navigate(["/"]);
    } catch (error) {
      console.log(error);
    }
  }

  async login(email: string, password: string) {
    return from(signInWithEmailAndPassword(this.auth, email, password)
      .then(userCredential => {
        this.ngZone.run(() => {
          sessionStorage.setItem('lastTime', userCredential.user.metadata.lastSignInTime.substring(0, 17).trim());
          this.router.navigate(['account']);
          this.eventAuthError.next("");
        })
      })
      .catch((error) => {
        this.eventAuthError.next(error);
      })
    );
  }
  
  ///// Role-based Authorization //////

  canRead(user: User): boolean {
    const allowed = ["canvasser", "rep", "fin", "admin"];
    return this.checkAuthorization(user, allowed);
  }

  canEdit(user: User): boolean {
    const allowed = ["canvasser", "rep", "fin", "admin"];
    return this.checkAuthorization(user, allowed);
  }

  canAdmin(user: User): boolean {
    const allowed = ["admin"];
    return this.checkAuthorization(user, allowed);
  }

    // determines if user has matching role
    private checkAuthorization(user: User, allowedRoles: string[]): boolean {
      if (!user) return false;
      for (const role of allowedRoles) {
        if (user.role == role) {
          return true;
        }
      }
      return false;
    }

    updatePassword(password: string): boolean {
      const user = this.auth.currentUser;
      try {
        updatePassword(user, password);
        return true;
      } catch (error) {
        this.eventAuthError.next(error);
      }
    }

}
