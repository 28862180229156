import { Injectable } from '@angular/core';
/* Import firebase v9 */
import { app as firebase } from '../../../firebase-config';
import { getFirestore, collection, getDocs, DocumentData, QuerySnapshot, DocumentSnapshot, doc, getDoc, updateDoc, arrayUnion, addDoc, where, query, deleteDoc, orderBy } from 'firebase/firestore';
import { BehaviorSubject } from 'rxjs';
import { Donor } from 'src/app/models/donor';
import { Donation } from 'src/app/models/donation';

@Injectable({
  providedIn: 'root'
})
export class DonorsService {

  private eventAuthError = new BehaviorSubject<string>("");
  eventAuthError$ = this.eventAuthError.asObservable();
  private db = getFirestore(firebase);

  constructor() { }

  async getAllDonorsById(userId: string): Promise<Donor[]> {
    let donors: Donor[] = [];
    try {
      const q =  query(collection(this.db, "users", userId, "donors"), orderBy("createdDonorDate"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(doc => {
        // doc.data() is never undefined for query doc snapshots
        const data = doc.data() as Donor;
        donors.push(data);
      });
      return donors;
    } catch (error) {
      this.eventAuthError.next(error);
    }
  }

  public async addDonor(currentUserId: string, donor: Donor): Promise<Donor> {
    try {
      const docRef = await addDoc(collection(this.db, 'users', currentUserId, "donors"), donor);
      if (docRef) {
        await updateDoc(docRef, {
          donorId: docRef.id,
          donorShortId: docRef.id.substring(0, 5)
        })
      }
      return donor;
    } catch (error) {
      this.eventAuthError.next(error);
      throw new Error(error);
    }
  }

  public async addDonorFamilyName(familyName: any) {
    try {
      await addDoc(collection(this.db, "familyNames"), familyName);
    } catch (error) {
      this.eventAuthError.next(error);
      throw new Error(error);
    }
  }

  public async getDonorsFamilyNames(): Promise<string[]> {
    let familyNames: string[] = [];
    try {
      const q =  query(collection(this.db, "familyNames"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(doc => {
        // doc.data() is never undefined for query doc snapshots
        const data = doc.data().familyName as string;
        familyNames.push(data);
      });
      return familyNames;
    } catch (error) {
      this.eventAuthError.next(error);
    }
  } 


  public async getDonorByCanvasserOrRep(userId: string, donorId: string): Promise<Donor> {
    try {
      const docRef = doc(this.db, "users", userId, "donors", donorId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const donor = docSnap.data() as Donor;
        const donations = await this.getDonorRelatedDonationsById(userId, donorId);
        donor.listOfDonations = donations;
        return donor;
      } else {
        return null;
      }  
    } catch (error) {
      this.eventAuthError.next(error);
    }
  }

  public async getDonorRelatedDonationsById(userId: string, donorId: string): Promise<Donation[]>{
    let donations: Donation[] = [];
    try {
      const q =  query(collection(this.db, "users", userId, "donors", donorId, "donations"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(doc => {
        // doc.data() is never undefined for query doc snapshots
        const data = doc.data() as Donation;
        donations.push(data);
      });
      return donations;
    } catch (error) {
      this.eventAuthError.next(error);
    }
  }

  /*
  public async getDonorById(userId: string, donorId: string): Promise<Donor> {
    try {
      const docRef = doc(this.db, "users", userId, "donors", donorId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const donor = docSnap.data() as Donor;
        const donations = await this.getDonorRelatedDonations(userId, donorId);
        donor.listOfDonations = donations;
        return donor;
      } else {
        return null;
      }  
    } catch (error) {
      this.eventAuthError.next(error);
    }
  }
  */

  public async getDonorRelatedDonations(userId: string, donorId: string): Promise<Donation[]>{
    let donations: Donation[] = [];
    try {
      const q =  query(collection(this.db, "users", userId, "donors", donorId, "donations"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(doc => {
        // doc.data() is never undefined for query doc snapshots
        const data = doc.data() as Donation;
        donations.push(data);
      });
      return donations;
    } catch (error) {
      this.eventAuthError.next(error);
    }
  }

 
  


}
