import { Injectable } from '@angular/core';
/* Import firebase v9 */
import { app as firebase } from '../../../firebase-config';
import { getFirestore, collection, getDocs, DocumentData, QuerySnapshot, DocumentSnapshot, doc, getDoc, updateDoc, arrayUnion, addDoc, where, query, deleteDoc, orderBy, deleteField } from 'firebase/firestore';
import { BehaviorSubject } from 'rxjs';
import { Donor } from 'src/app/models/donor';
import { Donation } from 'src/app/models/donation';

@Injectable({
  providedIn: 'root'
})
export class DonationsService {

  private eventAuthError = new BehaviorSubject<string>("");
  eventAuthError$ = this.eventAuthError.asObservable();
  private db = getFirestore(firebase);

  constructor() { }

  async getAllDonationByDonorId(currentUserid: string, donorId: string): Promise<Donation[]> {
    let donations: Donation[] = [];
    try {
      const q =  query(collection(this.db, "users", currentUserid, "donors", donorId, "donations"), orderBy("createdDate"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(doc => {
        // doc.data() is never undefined for query doc snapshots
        const data = doc.data() as Donation;
        donations.push(data);
      });
      return donations;
    } catch (error) {
      this.eventAuthError.next(error);
    }
  }

  async getDonationByRepId(currentUserid: string, donorId: string, donationId: string): Promise<Donation> {
    try {
      const docRef = doc(this.db, "users", currentUserid, "donors", donorId, "donations", donationId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const donation = docSnap.data() as Donation;
        return donation;
      } else {
        return null;
      }
    } catch (error) {
      this.eventAuthError.next(error);
    }
  }

  public async updateDonationData(currentUserId: string,  donorId: string, donationId: string, donation: Donation): Promise<Donation> {
    try {
      const docRef = doc(this.db, 'users', currentUserId, "donors", donorId, "donations", donationId);
      if (docRef) {
        await updateDoc(docRef, {
          amount: donation.amount,
          currency: donation.currency,
          projectId: donation.projectId
        })
      };
      return donation;
    } catch (error) {
      this.eventAuthError.next(error);
      throw new Error(error);
    }
  }

  public async updateDonationStatusToTransfered(donation: Donation, exchangeRate: number, commissionPercentage: number, commission: number, convertedAmount: number): Promise<Donation> {
    try {
      const docRef = doc(this.db, 'users', donation.canvasserOrRepCrossRefId, "donors", donation.donorId, "donations", donation.donationId);
      if (docRef) {
        await updateDoc(docRef, {
          donationStatus: "Transfered",
          exchangeRate: exchangeRate,
          commissionPercentage: commissionPercentage,
          commission: commission,
          convertedAmount: convertedAmount
        });
      };
      return donation;
    } catch (error) {
      this.eventAuthError.next(error);
      throw new Error(error);
    }
  }

  public async updateDonationStatusToReceived(donation: Donation): Promise<Donation> {
    try {
      const docRef = doc(this.db, 'users', donation.canvasserOrRepCrossRefId, "donors", donation.donorId, "donations", donation.donationId);
      if (docRef) {
        await updateDoc(docRef, {
          donationStatus: "Received"
        });
      };
      return donation;
    } catch (error) {
      debugger;
      this.eventAuthError.next(error);
      throw new Error(error);
    }
  }

  public async updateDonationStatusToApproved(donation: Donation): Promise<Donation> {
    try {
      const docRef = doc(this.db, 'users', donation.canvasserOrRepCrossRefId, "donors", donation.donorId, "donations", donation.donationId);
      if (docRef) {
        await updateDoc(docRef, {
          donationStatus: "Approved"
        })
      };
      return donation;
    } catch (error) {
      this.eventAuthError.next(error);
      throw new Error(error);
    }
  }


}
