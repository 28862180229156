<footer class="footer">
    <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutAlign="center center" class="border-bottom-mute subscribe-block theme-container">
        <div fxFlex="100" fxFlex.gt-sm="40" fxLayout="column" fxLayoutAlign="center center"> 
            <h1 class="fw-500">Subscribe our Newsletter</h1>
            <h3 class="fw-300 secondary-color">Stay up to date with our latest new and products</h3>
        </div>
        <form method="get" class="subscribe-search-form" fxLayout="row" fxFlex="100" fxFlex.gt-sm="42.4" ngClass.sm="mt-2" ngClass.xs="mt-2">
            <input type="text" placeholder="Your email address..." fxFlex>
            <button mat-raised-button color="accent" (click)="subscribe()" type="button" class="mat-elevation-z0 text-muted">Subscribe</button>
        </form>
    </div>
        
</footer>