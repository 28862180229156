<div class="top-menu" *ngIf="isUserVerified$ | async; else logoutState"> 
    <div *ngIf="auth.user$ | async as user">
        <a mat-button routerLink="/auth/logout" style="color: white"><mat-icon>power_settings_new</mat-icon>Logout</a>
        <a mat-button [matMenuTriggerFor]="accountMenu" #accountMenuTrigger="matMenuTrigger">
            <mat-icon class="mat-icon-sm account-icon" style="color: white">person</mat-icon> <span fxShow="false" fxShow.gt-sm>{{user.fname}}</span> <mat-icon class="mat-icon-sm caret">arrow_drop_down</mat-icon> 
        </a>

        <mat-menu #accountMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="app-dropdown account">
            <span (mouseleave)="accountMenuTrigger.closeMenu()">
                <div fxLayout="row" fxLayoutAlign="space-around center" class="user-info">
                    <mat-icon>person</mat-icon>
                </div>
                <div class="divider"></div>
                <a mat-menu-item routerLink="/account">
                    <mat-icon class="mat-icon-sm">settings</mat-icon>
                    <span>My account</span>
                </a>
            </span>
        </mat-menu>
    </div>
</div>

<ng-template #logoutState>
    <div class="top-menu">    
        <div>
            <a mat-button routerLink="/auth" style="color: white"><mat-icon>exit_to_app</mat-icon>Login</a>   
        </div>
    
    </div>  
</ng-template>



