<mat-toolbar class="top-navbar2 mat-elevation-z2" fxLayoutAlign="center center">
    
    <!-- Start Top Fashion category -->
    <a mat-button [matMenuTriggerFor]="Fashion">Fashion<mat-icon class="caret">arrow_drop_down</mat-icon></a>
    <mat-menu #Fashion="matMenu" [overlapTrigger]="false" class="app-dropdown"> 
        <a mat-menu-item [routerLink]="['/srchByCategory', 'See all in Fashion']" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">See all in Fashion</a> 
        <a mat-menu-item [matMenuTriggerFor]="WomensClothing">Women's clothing</a> 
        <a mat-menu-item [matMenuTriggerFor]="MensClothing">Men's clothing</a> 
        <a mat-menu-item [matMenuTriggerFor]="KidsBabyClothing">Kids & Baby Clothing</a> 
        <a mat-menu-item [matMenuTriggerFor]="Jewellery">Jewellery</a>
        <a mat-menu-item [matMenuTriggerFor]="Shoes">Shoes</a>
        <a mat-menu-item [matMenuTriggerFor]="Watches">Watches</a>
        <a mat-menu-item [matMenuTriggerFor]="HandbagsAccessories">Handbags & Accessories</a>        
    </mat-menu>

    <!-- Women's Clothing -->
    <mat-menu #WomensClothing="matMenu" class="app-dropdown">
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Dresses']">Dresses</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Tops & Blouses']">Tops & Blouses</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Women Jeans']">Women's Jeans</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Women Pants']">Women's Pants</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Women Sweaters']">Women's Sweaters</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Women Coats & Jackets']">Women's Coats & Jackets</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Leggings']">Leggings</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Womens Activewear']">Women's Activewear</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Maternity Clothing']">Maternity Clothing</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Others Womens Clothing']">Others Women's Clothing</a>
    </mat-menu>

    <!-- Men's Clothing -->
    <mat-menu #MensClothing="matMenu" class="app-dropdown">
        <a mat-menu-item [routerLink]="['/srchByCategory', 'T-Shirts']">T-Shirts</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Men Jeans']">Men's Jeans</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Men Pants']">Men's Pants</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Dress Shirts']">Dress Shirts</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Men Activewear']">Men's Activewear</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Sweats & Hoodies']">Sweats & Hoodies</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Men Sweaters']">Men's Sweaters</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Men Coats & Jackets']">Men's Coats & Jackets</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Men Suits']">Men's Suits</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Others Men Clothing']">Others Men's Clothing</a>
    </mat-menu>

    <!-- Kids & Baby Clothing -->
    <mat-menu #KidsBabyClothing="matMenu" class="app-dropdown">
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Baby Clothing (Newborn - 5T)']">Baby Clothing (Newborn - 5T)</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Girls Clothing (Sizes 4 & Up)']">Girls' Clothing (Sizes 4 & Up)</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Boys Clothing (Sizes 4 & Up)']">Boys' Clothing (Sizes 4 & Up)</a>
    </mat-menu>

    <!-- Jewellery -->
    <mat-menu #Jewellery="matMenu" class="app-dropdown">
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Fashion Necklaces & Pendants']">Fashion Necklaces & Pendants</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Fine Necklaces & Pendants']">Fine Necklaces & Pendants</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Fashion Earrings']">Fashion Earrings</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Fine Earrings']">Fine Earrings</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Fashion Bracelets']">Fashion Bracelets</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Fine Bracelets']">Fine Bracelets</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Fashion Rings']">Fashion Rings</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Fine Rings']">Fine Rings</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Engagement & Wedding']">Engagement & Wedding</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Mens Jewellery']">Men's Jewellery</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Others Jewellery']">Others Jewellery</a>
    </mat-menu>

     <!-- Shoes -->
     <mat-menu #Shoes="matMenu" class="app-dropdown">
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Womens Shoes']">Women's Shoes</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Mens Shoes']">Men's Shoes</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Girls Shoes']">Girls's Shoes</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Boys Shoes']">Boys's Shoes</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Baby & Toddler Shoes']">Baby & Toddler Shoes</a>
    </mat-menu>

     <!-- Watches -->
     <mat-menu #Watches="matMenu" class="app-dropdown">
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Mens Watches']">Men's Watches</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Womens Watches']">Women's Watches</a>
    </mat-menu>

     <!-- Handbags & Accessories -->
     <mat-menu #HandbagsAccessories="matMenu" class="app-dropdown">
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Womens Accessories']">Women's Accessories</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Mens Accessories']">Men's Accessories</a>
    </mat-menu>

    <!-- End Top Fashion category -->

    <!-- Start Top Electronics category -->
    <a mat-button [matMenuTriggerFor]="Electronics">Electronics<mat-icon class="caret">arrow_drop_down</mat-icon></a>
    <mat-menu #Electronics="matMenu" [overlapTrigger]="false" class="app-dropdown"> 
        <a mat-menu-item [routerLink]="['/srchByCategory', 'See all in Electronics']" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">See all in Electronics</a> 
        <a mat-menu-item [matMenuTriggerFor]="ComputersTablets">Computers & Tablets</a> 
        <a mat-menu-item [matMenuTriggerFor]="CellPhonesSmartphones">Cell Phones & Smartphones</a> 
        <a mat-menu-item [matMenuTriggerFor]="TVHomeEntertainment">TV & Home Entertainment</a>
        <a mat-menu-item [matMenuTriggerFor]="Refurbished">Refurbished</a>      
        
    </mat-menu>

    <!-- Computers & Tablets -->
    <mat-menu #ComputersTablets="matMenu" class="app-dropdown">
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Laptops & Netbooks']">Laptops & Netbooks</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Computer Components & Parts']">Computer Components & Parts</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Desktops & All-In-Ones']">Desktops & All-In-Ones</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Computer Cables & Connectors']">Computer Cables & Connectors</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Drives & Storages']">Drives & Storages</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Keyboards, Mice & Pointers']">Keyboards, Mice & Pointers</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Laptop & Desktop Accessories']">Laptop & Desktop Accessories</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Monitors & Projectors']">Monitors & Projectors</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Printers, Scanners & Supplies']">Printers, Scanners & Supplies</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Tablet & eBook Readers']">Tablet & eBook Readers</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Tablet & eBook Readers Parts']">Tablet & eBook Readers Parts</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Others Computers & Tablets']">Others Computers & Tablets</a>
    </mat-menu>

    <mat-menu #CellPhonesSmartphones="matMenu" class="app-dropdown">
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Cell Phones & Smartphones']">Cell Phones & Smartphones</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Cellp Phone Accessories']">Cell Phone & Smartphones Accessories</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Cell Phones & Smartphones Parts']">Cell Phones & Smartphones Parts</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Others Cell Phones & Smartphones']">Others Cell Phones & Smartphones</a>
    </mat-menu>

    <mat-menu #TVHomeEntertainment="matMenu" class="app-dropdown">
        <a mat-menu-item [routerLink]="['/srchByCategory', 'TV & Video']">TV & Video</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Home Audio']">Home Audio</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Media Streamers']">Media Streamers</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'TV, Video & Audio Accessories']">TV, Video & Home Audio Accessories</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'TV, Video & Audio Parts']">TV, Video & Home Audio Parts</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Others TV, Video & Home Audio']">Others TV, Video & Home Audio</a>
    </mat-menu>

    <mat-menu #Refurbished="matMenu" class="app-dropdown">
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Laptops']"> Refurbished Laptops</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Portable Audio & Headphones']">Refurbished Portable Audio & Headphones</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Desktop Computers']">Refurbished Desktop Computers</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Monitors']">Refurbished Monitors</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Monitors']">Others Refurbished</a>
    </mat-menu>
     <!-- End Top Electronics category -->

    <!-- Start Top Furniture category -->
    <a mat-button [matMenuTriggerFor]="Furnitures">Furnitures<mat-icon class="caret">arrow_drop_down</mat-icon></a>
    <mat-menu #Furnitures="matMenu" [overlapTrigger]="false" class="app-dropdown"> 
        <a mat-menu-item [routerLink]="['/srchByCategory', 'See all in Furnitures']" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">See all in Furnitures</a> 
        <a mat-menu-item [matMenuTriggerFor]="LivingRoom">Living Room</a> 
        <a mat-menu-item [matMenuTriggerFor]="Bedroom">Bedroom</a> 
        <a mat-menu-item [matMenuTriggerFor]="DiningRoom">Dining Room</a>
        <a mat-menu-item [matMenuTriggerFor]="KidsRoom">Kids Room</a>       
    </mat-menu>

    <!-- Living Room -->
    <mat-menu #LivingRoom="matMenu" class="app-dropdown">
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Middle East Furniture']">Middle East Furniture</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Sofas']">Sofas</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Chairs']">Chairs</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Loveseats']">Loveseats</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Sectionals']">Sectionals</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Recliners']">Recliners</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Coffee Tables']">Coffee Tables</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Others Living Room']">Others Living Room</a>
    </mat-menu>

    <!-- Bedroom -->
    <mat-menu #Bedroom="matMenu" class="app-dropdown">
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Queen Beds']">Queen Beds</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'King Beds']">King Beds</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Full Beds']">Full Beds</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Dressers & Chests']">Dressers & Chests</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Night Tables']">Night Tables</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Mirrors']">Mirrors</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Others Bedroom']">Others Bedroom</a>
    </mat-menu>

    <!-- Dining Room -->
    <mat-menu #DiningRoom="matMenu" class="app-dropdown">
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Tables']">Tables</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Chairs']">Chairs</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Mirrors']">Mirrors</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Others Dining Room']">Others Dining Room</a>
    </mat-menu>

     <!-- Kids Room -->
     <mat-menu #KidsRoom="matMenu" class="app-dropdown">
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Bunk Beds']">Bunk Beds</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Twin Beds']">Twin Beds</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Full Beds']">Full Beds</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Desks & Hutches']">Desks & Hutches</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Baby Furniture']">Baby Furniture</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Others Kids Room']">Others Kids Room</a>
    </mat-menu>
    <!-- End Top Furniture category -->

    <!-- Start Top Appliances category -->
    <a mat-button [matMenuTriggerFor]="Appliances">Appliances<mat-icon class="caret">arrow_drop_down</mat-icon></a>
    <mat-menu #Appliances="matMenu" [overlapTrigger]="false" class="app-dropdown"> 
        <a mat-menu-item [routerLink]="['/srchByCategory', 'See all in Appliances']" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">See all in Appliances</a> 
        <a mat-menu-item [matMenuTriggerFor]="Kitchen">Kitchen</a> 
        <a mat-menu-item [matMenuTriggerFor]="Laundry">Laundry</a>       
        
    </mat-menu>

    <!-- Kitchen -->
    <mat-menu #Kitchen="matMenu" class="app-dropdown">
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Refrigerators']">Refrigerators</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Ranges']">Ranges</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Dishwashers']">Dishwashers</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Microwaves']">Microwaves</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Cooktops']">Cooktops</a> 
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Wall ovens']">Wall ovens</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Range hoods']">Range hoods</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Freezers']">Freezers</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Others Kitchen']">Others Kitchen</a>
    </mat-menu>

    <!-- Laundry -->
    <mat-menu #Laundry="matMenu" class="app-dropdown">
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Washers']">Washers</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Dryer']">Dryer</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Washer & Dryer Pairs']">Washer & Dryer Pairs</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Others Laundry']">Others Laundry</a>
    </mat-menu>
    <!-- End Top Appliances category -->


    <!-- Start Cars & Vehicules category -->
    <a mat-button [matMenuTriggerFor]="carsVehicules">Cars & Vehicules<mat-icon class="caret">arrow_drop_down</mat-icon></a>
    <mat-menu #carsVehicules="matMenu" [overlapTrigger]="false" class="app-dropdown"> 
        <a mat-menu-item [routerLink]="['/srchByCategory', 'See all in Cars & Vehicules']" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">See all in Cars & Vehicules</a>
        <a mat-menu-item [matMenuTriggerFor]="carsRef1">Cars, Trucks & Buses</a>  
        <a mat-menu-item [matMenuTriggerFor]="carsRef2">Vehicle Parts, Tires, & Accessories</a> 
       
    </mat-menu>

    <!-- Cars & Trucks and Buses -->
    <mat-menu #carsRef1="matMenu" class="app-dropdown">
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Cars']">Cars</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Trucks']">Trucks</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Buses']">Buses</a>
    </mat-menu>

    <!-- Vehicle Parts, Tires, & Accessories -->
    <mat-menu #carsRef2="matMenu" class="app-dropdown">
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Audio & GPS']">Audio & GPS</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Engine & Engine Parts']">Engine & Engine Parts</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Heavy Equipment Parts & Accessories']">Heavy Equipment Parts & Accessories</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Tires & Rims']">Tires & Rims</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Transmission & Drivetrain']">Transmission & Drivetrain</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Other Parts & Accessories']">Other Parts & Accessories</a>
    </mat-menu>

    <!-- End Cars & Vehicules category -->

    <!-- Start Real Estate category -->
    <a mat-button [matMenuTriggerFor]="realEstate">Real Estate<mat-icon class="caret">arrow_drop_down</mat-icon></a>
    <mat-menu #realEstate="matMenu" [overlapTrigger]="false" class="app-dropdown">  
        <a mat-menu-item [routerLink]="['/srchByCategory', 'See all in Real Estate']" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">See all in Real Estate</a> 
        <a mat-menu-item [matMenuTriggerFor]="rentRef1">For Rent</a> 
        <a mat-menu-item [matMenuTriggerFor]="saleRef1">For Sale</a>
        <a mat-menu-item routerLink="/products" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">Real Estate Services</a>
    </mat-menu>

    <!-- For Rent -->
    <mat-menu #rentRef1="matMenu" class="app-dropdown">
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Long Term Rentals']">Long Term Rentals</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Short Term Rentals']">Short Term Rentals</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Storage for Rent']">Storage for Rent</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Parking for Rent']">Parking for Rent</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Commercial & Office Space for Rent']">Commercial & Office Space for Rent</a>
    </mat-menu>

    <!-- For Sale -->
    <mat-menu #saleRef1="matMenu" class="app-dropdown">
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Houses for Sale']">Houses for Sale</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Condos for Sale']">Condos for Sale</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Land for Sale']">Land for Sale</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Commercial & Office Space for Sale']">Commercial & Office Space for Sale</a>
    </mat-menu>

    <!-- End Real Estate category -->

    <!-- Start Health & Beauty category -->
    <a mat-button [matMenuTriggerFor]="HealthBeauty">Health & Beauty<mat-icon class="caret">arrow_drop_down</mat-icon></a>
    <mat-menu #HealthBeauty="matMenu" [overlapTrigger]="false" class="app-dropdown"> 
        <a mat-menu-item [routerLink]="['/srchByCategory', 'See all in Health & Beauty']" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">See all in Health & Beauty</a> 
        <a mat-menu-item [matMenuTriggerFor]="Fragrances">Fragrances</a> 
        <a mat-menu-item [matMenuTriggerFor]="BathBody">Bath & Body</a>
        <a mat-menu-item [matMenuTriggerFor]="SkinCare">Skin Care</a>
       
    </mat-menu>

    <!-- For Fragrances -->
    <mat-menu #Fragrances="matMenu" class="app-dropdown">
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Women Fragrances']">Women's Fragrances</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Men Fragrances']">Men's Fragrances</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Kids Fragrances']">Kids's Fragrances</a>
    </mat-menu>

    <!-- For Bath & Body -->
    <mat-menu #BathBody="matMenu" class="app-dropdown">
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Body Sprays & Mists']">Body Sprays & Mists</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Body Soaps']">Body Soaps</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Deodourants & Antiperspirants']">Deodourants & Antiperspirants</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Body Washes & Shower Gels']">Body Washes & Shower Gels</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Body Washes & Shower Gels']">Bath Sets & Kits</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Body Washes & Shower Gels']">Bath & Body Mixed Items</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Body Washes & Shower Gels']">Body Powders</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Body Washes & Shower Gels']">Hand Washes</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Body Washes & Shower Gels']">Travel Bottles & Containers</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Body Washes & Shower Gels']">Bath Oils</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Body Washes & Shower Gels']">Others Bath Oils</a>
    </mat-menu>

     <!-- For Skin Care -->
     <mat-menu #SkinCare="matMenu" class="app-dropdown">
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Moisturizers']">Moisturizers</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Anti-Aging Products']">Anti-Aging Products</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Cleansers & Toners']">Cleansers & Toners</a>
        <a mat-menu-item [routerLink]="['/srchByCategory', 'Lip Balm & Treatments']">Lip Balm & Treatments</a>
    </mat-menu>

    <!-- End Health & Beauty category -->

    <!-- Start Services category -->
    <a mat-button [matMenuTriggerFor]="services">Services<mat-icon class="caret">arrow_drop_down</mat-icon></a>
    <mat-menu #services="matMenu" [overlapTrigger]="false" class="app-dropdown">  
        <a mat-menu-item [routerLink]="['/srchByCategory', 'See all in Services']" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">See all in Services</a> 
        <a mat-menu-item routerLink="/products" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">Childcare & Nanny</a>
        <a mat-menu-item routerLink="/products" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">Cleaners & Cleaning</a>
        <a mat-menu-item routerLink="/products" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">Financial & Legal</a>
        <a mat-menu-item routerLink="/products" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">Food & Catering</a>
        <a mat-menu-item routerLink="/products" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">Health & Beauty</a>
        <a mat-menu-item routerLink="/products" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">Moving & Storage</a>
        <a mat-menu-item routerLink="/products" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">Tutors & Languages</a>
        <a mat-menu-item [matMenuTriggerFor]="skilledTrades">Skilled Trades</a> 
    </mat-menu>

    <!-- Skilled Trades -->
    <mat-menu #skilledTrades="matMenu" class="app-dropdown">
    <a mat-menu-item>Appliance Repair & Installation</a>
    <a mat-menu-item>Brick, Masonry & Concrete</a>
    <a mat-menu-item>Carpentry, Crown Moulding & Trimwork</a>
    <a mat-menu-item>Drywall & Stucco Removal</a>
    <a mat-menu-item>Electrician</a>
    <a mat-menu-item>Flooring</a>
    <a mat-menu-item>Heating, Ventilation & Air Conditioning</a>
    <a mat-menu-item>Interlock, Paving & Driveways</a>
    <a mat-menu-item>Painters & Painting</a>
    <a mat-menu-item>Phone, Network, Cable & Home-wiring</a>
    <a mat-menu-item>Plumbing</a>
    <a mat-menu-item>Renovations, General Contracting & Handyman</a>
    <a mat-menu-item>Roofing</a>
    <a mat-menu-item>Welding</a>
    </mat-menu>

    <!-- End Services category -->

</mat-toolbar>

