import { Injectable } from '@angular/core';

/* Import firebase v9 */
import { app as firebase } from '../../../firebase-config';
import { getFirestore, collection, getDocs, DocumentData, QuerySnapshot, DocumentSnapshot, doc, getDoc, updateDoc, arrayUnion, addDoc, where, query, deleteDoc, orderBy } from 'firebase/firestore';
import { BehaviorSubject } from 'rxjs';
import { Project } from 'src/app/models/project';
import { Donation } from 'src/app/models/donation';
import { Donor } from 'src/app/models/donor';
import { Transfer } from 'src/app/models/transfer';
import { User } from 'src/app/models/user';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  private eventAuthError = new BehaviorSubject<string>("");
  eventAuthError$ = this.eventAuthError.asObservable();
  private db = getFirestore(firebase);
  
  constructor() {}


  public async addProject(currentUserId: string, project: Project): Promise<Project> {
    try {
      const docRef = await addDoc(collection(this.db, "projects"), project);
      if (docRef) {
        await updateDoc(docRef, {
          projectId: docRef.id,
          projectShortId: docRef.id.substring(0, 5)
        })
      }
      return project;
    } catch (error) {
      this.eventAuthError.next(error);
      throw new Error(error);
    }
  }

  public async addDonation(currentUserId: string, donorId: string, donation: Donation): Promise<Donation> {
    try {
      const docRef = await addDoc(collection(this.db, 'users', currentUserId, "donors", donorId, "donations"), donation);
      if (docRef) {
        await updateDoc(docRef, {
          donationId: docRef.id,
          donationShortId: docRef.id.substring(0, 5)
        })
      }
      return donation;
    } catch (error) {
      this.eventAuthError.next(error);
      throw new Error(error);
    }
  }

  public async addTransfer(currentUserId: string, transfer: Transfer): Promise<Transfer> {
    try {
      const docRef = await addDoc(collection(this.db, 'users', currentUserId, "transfers"), transfer);
      if (docRef) {
        await updateDoc(docRef, {
          transferId: docRef.id,
          transferShortId: docRef.id.substring(0, 5)
        })
      }
      return transfer;
    } catch (error) {
      this.eventAuthError.next(error);
      throw new Error(error);
    }
  }

  
  


  async getAllUsersByAdmin(userId: string): Promise<Donor[]> {
    let users: User[] = [];
    try {
      const q =  query(collection(this.db, "users"), orderBy("createdUserDate"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(doc => {
        // doc.data() is never undefined for query doc snapshots
        const data = doc.data() as User;
        users.push(data);
      });
      return users;
    } catch (error) {
      this.eventAuthError.next(error);
    }
  }

  public async getProjectById(projectId: string): Promise<Project> {
    try {
      const docRef = doc(this.db, "projects", projectId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const project = docSnap.data() as Project;
        const donations = await this.getProjectRelatedDonations(projectId);
        project.listOfDonations = donations;
        return project;
      } else {
        return null;
      }  
    } catch (error) {
      this.eventAuthError.next(error);
    }
  }

  /*
  public async getDonationByDonorId(userId: string, donorId: string): Promise<Donor> {
    try {
      const docRef = doc(this.db, "users", userId, "donors", donorId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const donor = docSnap.data() as Donor;
        const donations = await this.getDonorRelatedDonations(userId, donorId);
        donor.listOfDonations = donations;
        return donor;
      } else {
        return null;
      }  
    } catch (error) {
      this.eventAuthError.next(error);
    }
  }
  */

  public async deleteDonationById(userId: string, donorId: string, donationId: string): Promise<void> {
    try {
      return await deleteDoc(doc(this.db, "users", userId, "donors", donorId, "donations", donationId));
    } catch (error) {
      this.eventAuthError.next(error);
    }
  }

  public async deleteDonorById(userId: string, donorId: string): Promise<void> {
    try {
      return await deleteDoc(doc(this.db, "users", userId, "donors", donorId));
    } catch (error) {
      this.eventAuthError.next(error);
    }
  }

  public async deleteProfileById(userId: string, profileId: string): Promise<void> {
    try {
      return await deleteDoc(doc(this.db, "users", userId, "profiles", profileId));
    } catch (error) {
      this.eventAuthError.next(error);
    }
  }

 

 
  public async getProjectRelatedDonations(projectId: string): Promise<Donation[]>{
    let donations: Donation[] = [];
    try {
      const q =  query(collection(this.db, "projects", projectId, "donations"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(doc => {
        // doc.data() is never undefined for query doc snapshots
        const data = doc.data() as Donation;
        donations.push(data);
      });
      return donations;
    } catch (error) {
      this.eventAuthError.next(error);
    }
  }

  public async updateProjectData(currentUserId: string,  projectId: string, project: Project): Promise<Project> {
    try {
      const docRef = doc(this.db, "projects", projectId);
      if (docRef) {
        await updateDoc(docRef, {
          projectName: project.projectName,
          projectStartDate: project.projectStartDate,
          projectEndDate: project.projectEndDate,
          projectStatus: project.projectStatus,
          projectDescription: project.projectDescription
        })
      };
      return project;
    } catch (error) {
      this.eventAuthError.next(error);
      throw new Error(error);
    }
  }

  public async updateDonorData(currentUserId: string,  donorId: string, donor: Donor): Promise<Donor> {
    try {
      const docRef = doc(this.db, 'users', currentUserId, "donors", donorId);
      if (docRef) {
        await updateDoc(docRef, {
          fname: donor.fname,
          mname: donor.mname,
          lname: donor.lname,
          familyName: donor.familyName,
          sexe: donor.sexe,
          country: donor.country, 
          state: donor.state, 
          city: donor.city,       
          donorStatus: donor.donorStatus,
          originalSSCDistrictTown: donor.originalSSCDistrictTown,
        })
      };
      return donor;
    } catch (error) {
      this.eventAuthError.next(error);
      throw new Error(error);
    }
  }

}
